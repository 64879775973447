<template>
    <div class="border-b border-gray-200 py-4">
        <div
            class="mb-2 flex cursor-pointer items-center justify-between"
            @click="expanded = !expanded"
        >
            <h4 class="text-grind font-normal" :class="{ 'text-brew font-bold': expanded }">{{ title }}</h4>
            <button
                aria-label="Toggle Filter Group"
                class="text-grind transition-all"
                :class="[expanded ? 'rotate-180' : 'rotate-0']"
            >
                <Icon name="chevron-down" class="text-sm" />
            </button>
        </div>
        <TransitionHeight ref="transitionHeightRef">
            <div
                :style="{ height: expanded ? 'auto' : '0' }"
                class="overflow-hidden"
            >
                <slot></slot>
            </div>
        </TransitionHeight>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    expandedDefault: {
        type: Boolean,
        default: false,
        required: false,
    },
    mobileMenuOpen: {
        type: Boolean,
        default: false,
    },
    count: {
        type: Number,
        default: 0,
    },
});

const expanded = ref<boolean>(props.expandedDefault);
const transitionHeightRef = ref();
watch(
    () => expanded.value,
    () => {
        transitionHeightRef.value.recalculateHeight();
    },
);
watch(
    () => props.mobileMenuOpen,
    () => {
        transitionHeightRef.value.recalculateHeight();
    },
);
watch(
    () => props.count,
    () => {
        transitionHeightRef.value.recalculateHeight();
    },
);
</script>
